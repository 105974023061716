import {apiUrl} from './config';

export default class APIService {
    
    static DeletePatient(id, token) {
        return fetch(`${apiUrl}/api/healthcheck/resultpage/${id}/`, {
            'method': 'DELETE',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
    }

    static UpdatePatient(id, body, token) {
        return fetch(`${apiUrl}/api/healthcheck/resultpage/${id}/`, {
            'method': 'PUT',
            headers:{
                'Authorization': `Token ${token}`
            },
            body:body
        }).then(reps => reps.json())
    }


    static InsertPatient(body, token) {
        return fetch(`${apiUrl}/api/healthcheck/resultpage/`, {
            'method': 'POST',
            headers:{
                'Authorization': `Token ${token}`
            },
            body:body
        }).then(reps => reps.json())
    }

    static LoginUser(body) {
        return fetch(`${apiUrl}/api-token-auth/`, {
            'method': 'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(body)
        }).then(reps => reps.json())
    }
}
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Space, Button, Input, Image, message } from 'antd';
import AddPatient from '../components/AddPatient';
import {apiUrl} from '../config';
import { SearchOutlined, EyeOutlined, DownloadOutlined, DeleteOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import APIService from '../APIService';
import logo from '../containers/img/ic_icon.png';

function Patients(props){
    const [IdUser] = useState(localStorage.getItem('admin_id'))
    const [CompanyId, setCompanyId] = useState([])
    const [ResultPage, setResultPage] = useState([])
    const [ContactNum, SetContactNum] = useState([])
    const [DisplayPatient, setDisplayPatient] = useState(false)

    const [searchInput, setsearchInput] = useState('')
    const [searchText, setsearchText] = useState('')
    const [searchedColumn, setsearchedColumn] = useState('')

    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [action, setAction] = useState([]);

    let history = useHistory()

    useEffect(() => {
        fetch(`${apiUrl}/api/healthcheck/companylist/`, {
            'method': 'GET',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Token ${props.token}`
            }
        })
        .then(reps => reps.json())
        .then(reps => FilterUser(reps))
        .catch(error => console.log(error))

        fetch(`${apiUrl}/api/healthcheck/resultpage/`, {
            'method': 'GET',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Token ${props.token}`
            }
        })
        .then(reps => reps.json())
        .then(reps => FilterResultPage(reps))
        .catch(error => console.log(error))

        fetch(`${apiUrl}/api/healthcheck/contactnum/`, {
            'method': 'GET',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Token ${props.token}`
            }
        })
        .then(reps => reps.json())
        .then(reps => SetContactNum(reps))
        .catch(error => console.log(error))
    }, [])


    const FilterUser = (reps) => {
        reps && reps.map(listuser => {
            if(Number(listuser.user) === Number(IdUser)){
                setCompanyId(listuser.id)
            }
        })
    }

    const FilterResultPage = (reps) => {
        const new_user_list = []
        reps && reps.map(listuser => {
            if(listuser.company_user_id === Number(IdUser)){
                new_user_list.push({
                    'key': listuser.id,
                    'id': listuser.id,
                    'user': listuser.user,
                    'user_id': listuser.user,
                    'username': listuser.username,
                    'certificate_and_result': listuser.certificate_and_result,
                    'file_name': listuser.file_name,
                    'created_at': listuser.created_at,
                    'updated_at': listuser.updated_at,
                })
            }
        })
        setResultPage(new_user_list)
    }

    const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) =>{
        setSelectedRows(selectedRows)
        setSelectedRowsKeys(selectedRowKeys);
    }
    const rowSelection = {
        selectedRows,
        onChange: onSelectedRowKeysChange
    };

    const handleOnClick = () => {
        selectedRows.map((result) => {
            APIService.DeletePatient(result.id, props.token)
            .catch(error => console.log(error))
        })
        message.success({ content: "Successfully Deleted", duration: "2" })
        setInterval(() => {
            window.location.reload(false);
        }, 3000);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0])
        setsearchedColumn('title')
    }

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('')
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'username',
            key: 'username',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            setsearchInput(node);
                        }}
                        placeholder={`Search username`}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, 'username')}
                    />
                    <Space>
                        <Button
                            type="primary"
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                            onClick={() => handleSearch(selectedKeys, confirm, 'username')}
                        >
                            Search
                        </Button>
                        <Button size="small" style={{ width: 90 }} onClick={() => handleReset(clearFilters)}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => (record['username']) ? record['username'].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: visible =>{
                if(visible) {
                    setTimeout(() => searchInput, 100);
                }
            },
            render: text =>
                searchedColumn === 'username' ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Certificate and result',
            dataIndex: 'file_name',
            key: 'file_name',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            setsearchInput(node);
                        }}
                        placeholder={`Search Filename`}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, 'file_name')}
                    />
                    <Space>
                        <Button
                            type="primary"
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                            onClick={() => handleSearch(selectedKeys, confirm, 'file_name')}
                        >
                            Search
                        </Button>
                        <Button size="small" style={{ width: 90 }} onClick={() => handleReset(clearFilters)}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => (record['file_name']) ? record['file_name'].toString().toLowerCase().includes(value.toLowerCase()) : '',
            onFilterDropdownVisibleChange: visible =>{
                if(visible) {
                    setTimeout(() => searchInput, 100);
                }
            },
            render: text =>
                searchedColumn === 'file_name' ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Date and Time',
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
            sortDirections: ['descend', 'ascend'],
            render: (text) => moment(text).format('lll')
        },
        {
            title: 'Contact #',
            dataIndex: 'username',
            key: 'username',
            render: (text) =>
                <span>
                    {ContactNum.filter(ContactNum => String(ContactNum.user) === String(text)).map((ContactNum) => { return ContactNum.phone_number })}
                </span>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (action: any, record: {id: number}) => {
                return (
                    <>
                        <Space>
                            <Button type="primary" icon={<EyeOutlined />} size="large" onClick={() => ViewPatient(record.id)}>
                                View Detail
                            </Button>
                            <a href={record.certificate_and_result} target="_blank" rel="noreferrer noopener">
                                <Button type="primary" icon={<DownloadOutlined />} size="large" />
                            </a>
                            {/* <Button type="danger" size="large" icon={<DeleteOutlined />} onClick={() => DeletePatient(record.id)}>Delete</Button> */}
                        </Space>
                    </>
                );
            }
        }
    ]

    const ViewPatient = (id) =>{
        history.push(`/ViewPatientsDetails/${id}/`)
    }

    const deletePatientList = (Patient) => {
        const new_patient_list = ResultPage.filter(MyResultPage => {
            if(MyResultPage.id === Patient.id){
                return false
            }
            return true;
        })
        setResultPage(new_patient_list)
    }

    const DeletePatient = (id) =>{
        APIService.DeletePatient(id, props.token)
        .then(() => deletePatientList({ 
            'id': id,
        }))
        .catch(error => console.log(error))
    }

    const addPatient = () => {
        setDisplayPatient(true)
    }

    const insertedPatient = (Patient) => {
        const new_Patient = [...ResultPage, {
            'certificate_and_result': Patient.certificate_and_result,
            'created_at': Patient.created_at,
            'file_name': Patient.file_name,
            'id': Patient.id,
            'updated_at': Patient.updated_at,
            'user_id': Patient.user,
            'username': Patient.username,
        }]
        setResultPage(new_Patient)
        message.success({ content: "Successfully Added", duration: "2" })
        setInterval(() => {
            window.location.reload(false);
        }, 3000);
    }
    return (
        <div className="col-md-12">
            <h2>Patient</h2>
            <br/>
            { DisplayPatient ? <div key={ResultPage.id}>
                <AddPatient setDisplayPatient={setDisplayPatient} CompanyId={CompanyId} insertedPatient={insertedPatient}/>
            </div>: <div key={ResultPage.id}>
                <Space>
                    <Button type="primary" onClick={addPatient}>
                        Add Result
                    </Button>
                    <Button type="danger" disabled={selectedRows.length > 0 && action ? false : true} onClick={() => handleOnClick()}>DELETE</Button>
                </Space>
                <Table
                    rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={ResultPage}
                    size="small"
                    pagination={{ position: ['bottomLeft'], pageSize: 5 }}
                />
            </div>}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.token !== null,
        token: state.token
    }
}

export default withRouter(connect(mapStateToProps, null)(Patients));
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import APIService from '../APIService';
import { Space, Button, message, Select } from 'antd';
import {apiUrl} from '../config';

function AddPatient(props){
    const { Option } = Select;
    const [ListUsers, setListUser] = useState([]);
    const [username, setUsername] = useState('');
    const [attachment, setAttachment] = useState('');

    const [validationusername, setValidationUsername] = useState('');
    const [validationattachment, setValidationAttachment] = useState('');

    useEffect(() => {
        fetch(`${apiUrl}/users/`, {
            'method': 'GET',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Token ${props.token}`
            }
        })
        .then(reps => reps.json())
        .then(reps => FiltersetListUser(reps))
        .catch(error => console.log(error))
    }, [])

    const FiltersetListUser = (resp) => {
        const list_user = []
        resp.map(UserList => {
            if(UserList.is_superuser === false){
                list_user.push({
                    'id': UserList.id,
                    'username': UserList.username
                })
            }
        })
        setListUser(list_user)
    }

    
    const cancelPatient = () => {
        props.setDisplayPatient(false)
    }

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    }

    const newPatient = () => {
        if (username && attachment){
            const uploadData = new FormData();
            uploadData.append('user', username)
            uploadData.append('company', props.CompanyId)
            uploadData.append('certificate_and_result', attachment, attachment.name)
            uploadData.append('file_name', attachment.name)
            return fetch(`${apiUrl}/api/healthcheck/resultpage/`, {
                'method': 'POST',
                headers:{
                    'Authorization': `Token ${props.token}`
                },
                body:uploadData
            }).then(handleErrors)
            .then(reps => props.insertedPatient(reps))
            .catch((error) => {
                console.log(error)
                message.error(`Can't Upload this file ${error}`)
            })
        }
        
        if(username.length === 0){
            setValidationUsername('Username is required')
        }
        else{
            setValidationUsername('')
        }

        if(attachment.length === 0){
            setValidationAttachment('Upload File is required')
        }
        else{
            setValidationAttachment('')
        }

    }
    return (
        <div>
            <div className="form-group">
                <label htmlFor="username">Username</label>
                <br></br>
                <Select
                    showSearch
                    placeholder="Select a employee name"
                    optionFilterProp="children"
                    style={{width: '1610px', height: '34px', padding: '0px'}}
                    className="form-control"
                    onChange={(e) => {
                        {ListUsers && ListUsers.map(ListUser => {
                            if(ListUser.id === e){
                                setUsername(e)
                            }
                        })}
                    }}
                >
                    <Option selected disabled>Select Usernames</Option>
                    {ListUsers && ListUsers.map(ListUser => {
                        return (
                            <Option value={ListUser.id}>{ListUser.username}</Option>
                        )
                    })}
                </Select>
                {validationusername ? <span style={{color: "red"}}>{validationusername}</span> : null}
            </div>

            <div className="form-group">
                <label htmlFor="attachement">Upload File</label>
                <input type="file" className="form-control" onChange={(e) => setAttachment(e.target.files[0])}/>
                {validationattachment ? <span style={{color: "red"}}>{validationattachment}</span> : null}
            </div>
            
            <div className="form-group">
                <Space>
                    <Button type="primary"  onClick={() => newPatient()}>Add</Button>
                    <Button type="light"  onClick={() => cancelPatient()}>Cancel</Button>
                </Space>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.token !== null,
        token: state.token
    }
}

export default withRouter(connect(mapStateToProps, null)(AddPatient));
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import APIService from '../APIService';
import { Space, Button, Input, Form, PageHeader, Descriptions } from 'antd';
import {apiUrl} from '../config';

function ViewPatientsDetails(props){
    const { TextArea } = Input;
    const [form] = Form.useForm();
    let history = useHistory()
    const id = props.match.params.id

    const [ListUsers, setListUser] = useState([]);
    const [patient, setPatient] = useState([])
    const [username, setUsername] = useState('')
    const [user_id, setUser] = useState('')
    const [user_id_edit, setUserEdit] = useState('')
    const [file_name, setFilename] = useState('')
    const [attachement, setAttachment] = useState('')
    const [attachement_edit, setAttachmentEdit] = useState('')
    const [isDivVisibleUpdatePatient, setIsDivVisibleUpdatePatient] = useState(false)
    
    const [validation, setValidation] = useState('');

    useEffect(() => {
        fetch(`${apiUrl}/users/`, {
            'method': 'GET',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Token ${props.token}`
            }
        })
        .then(reps => reps.json())
        .then(reps => FiltersetListUser(reps))
        .catch(error => console.log(error))
    }, [])

    const FiltersetListUser = (resp) => {
        const list_user = []
        resp.map(UserList => {
            if(UserList.is_superuser === false){
                list_user.push({
                    'id': UserList.id,
                    'username': UserList.username
                })
            }
        })
        setListUser(list_user)
    }

    useEffect(() => {
        fetch(`${apiUrl}/api/healthcheck/resultpage/${id}/`, {
            'method': 'GET',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Token ${props.token}`
            }
        })
        .then(reps => reps.json())
        .then(reps => setPatient(reps))
        .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        setUser(patient.user)
        setUsername(patient.username)
        setFilename(patient.file_name)
        setAttachment(patient.certificate_and_result)
    })

    const handleOk = () => {
        const uploadData = new FormData();
        if (attachement_edit){
            uploadData.append('certificate_and_result', attachement_edit, attachement_edit.name)
            uploadData.append('file_name', attachement_edit.name)  
        }
        uploadData.append('user', user_id_edit)
        APIService.UpdatePatient(id ,uploadData, props.token)
        .then(resp => updatedPatient(resp))
    }

    const updatedPatient = (resp) => {
        setUser(resp.user)
        setUsername(resp.username)
        setFilename(resp.file_name)
        setAttachment(resp.certificate_and_result)
        setIsDivVisibleUpdatePatient(false)
        history.push(`/Patient/`)
    }
    
    const onCancel = () => {
        setIsDivVisibleUpdatePatient(false);
    };

    const showDiv = () => {
        setIsDivVisibleUpdatePatient(true)
    }
    return (
        <div>
            <div>
                { isDivVisibleUpdatePatient ? <div>
                    {validation ? <span style={{color: "red"}}>{validation}</span> : null}
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                                <select className="form-control" aria-label="Default select status" onChange={(e) => setUserEdit(e.target.value)}>
                                    <option selected disabled>Select Usernames</option>
                                    {ListUsers && ListUsers.map(ListUser => {
                                        return (
                                            <option value={ListUser.id}>{ListUser.username}</option>
                                        )
                                    })}
                                </select>
                            
                        </div>

                        <div className="form-group">
                            <label htmlFor="attachement">Upload File</label>
                            <input type="file" className="form-control" onChange={(e) => setAttachmentEdit(e.target.files[0])}/>
                        </div>
                        
                        <div className="form-group">
                            <Space>
                                <Button type="primary" onClick={handleOk}>Update</Button>
                                <Button type="light" onClick={onCancel}>Cancel</Button>
                            </Space>
                        </div>
                    </div> : <div>
                    <PageHeader
                            className="site-page-header"
                            onBack={() => history.push(`/Patient/`)}
                            title="Patient View"
                            subTitle="Detail"
                        />
                        <br />
                        <Button type="primary" onClick={() => showDiv()}>UPDATE</Button>
                        <br />
                        <Descriptions title={`Patient # ${id}`} bordered>
                            <Descriptions.Item label="Username"> {username} </Descriptions.Item>
                            <Descriptions.Item label="Attachment file"> <a href={attachement} target="_blank">{file_name}</a> </Descriptions.Item>
                        </Descriptions>
                    </div> 
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.token !== null,
        token: state.token
    }
}

export default withRouter(connect(mapStateToProps, null)(ViewPatientsDetails));
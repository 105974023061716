import React from 'react';
import { withRouter } from 'react-router-dom';
import { Image } from 'antd';
import { connect } from 'react-redux';
import logo from '../containers/img/ic_icon.png';
class DashboardList extends React.Component {
    render() {
        return (
            <div>
                <h2>DASHBOARD</h2>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        isAuthenticated: state.token !== null,
        token: state.token
    }
}

export default withRouter(connect(mapStateToProps, null)(DashboardList));
import React from 'react';
import { Layout, Menu, Image} from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import {
    DashboardOutlined,
    SettingOutlined,
    PoweroffOutlined,
    IdcardOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logo from '../containers/img/ic_icon.png';
const { Header, Content, Footer, Sider } = Layout;


class CustomLayout extends React.Component {

    state = { visible: false };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    render() {

        if (this.props.isAuthenticated) {
            return (
                <Layout>
                    <Sider
                        breakpoint="lg"
                        collapsedWidth="0"
                        onBreakpoint={broken => {
                            console.log(broken);
                            console.log(this.props.msg);
                        }}
                        onCollapse={(collapsed, type) => {
                            console.log(collapsed, type);
                        }}
                    >
                        <div className="logo" style={{ width: '20%' }}><img style={{ height: '40px', }} draggable="false" /></div>

                        <Menu theme="dark" mode="inline">
                            <Menu.Item key="1">
                                <Link to="/dashboard/">
                                    <span style={{ color: 'white'}}>
                                        <DashboardOutlined />
                                        Dashboard
                                    </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to="/Patient">
                                    <span style={{ color: 'white'}}>
                                        <IdcardOutlined />
                                        Patients
                                    </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="3" onClick={this.props.logout}>
                                <Link to="/login">
                                    <span style={{ color: 'white'}}>
                                        <PoweroffOutlined />
                                        Logout
                                    </span>
                                </Link>
                            </Menu.Item>

                        </Menu>
                    </Sider>
                    <Layout>
                        <Header style={{ padding: 0 }} />
                        <Content style={{ margin: '24px 16px 0' }}>
                            <div className="site-layout-background" style={{ padding: 24 }}>
                                <div class="row">
                                    <div class="col-md-12">
                                        <br></br>
                                        <a href="https://healthcheckcdo.com/" class="custom-logo-link" rel="home" aria-current="page">
                                            <Image
                                                width={100}
                                                src={logo}
                                            />
                                        </a>
                                        <br></br>
                                        <span>HealthCheck Clinic and Diagnostic Center</span>
                                    </div>
                                </div>
                                {this.props.children}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>HealthCheck © 2021</Footer>
                    </Layout>
                </Layout>
            );
        } else {
            return (
                <div />
            );
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    }
}


export default connect(null, mapDispatchToProps)(CustomLayout);




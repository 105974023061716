import React from 'react';
import { Route } from 'react-router-dom';
import NormalLoginForm from './containers/Login';
import DashboardList from './containers/DashboardList';
// import ChangePW from './containers/ChangePW';
import ForgetForm from './containers/UserForgetPass';
import Error404 from './containers/Error404';
// import DefaultPW from './components/DefaultPW';
import Patients from './components/Patients';
import ViewPatientsDetails from './components/ViewPatientsDetails';


const BaseRouter = () => (
    <div>
        <Route exact path='/ViewPatientsDetails/:id' component={ViewPatientsDetails} />
        <Route exact path='/dashboard/' component={DashboardList} />
        <Route exact path='/Patient/' component={Patients} />
        {/* <Route exact path='/change-password/' component={ChangePW} /> */}
        <Route exact path="/404" component={Error404} />
        {/* <Route exact path="/default-password/" component={DefaultPW} /> */}
    </div>

);

export const LoginRouter = () => (
    <div>
        <Route exact path='/' component={NormalLoginForm} />
        <Route exact path='/login' component={NormalLoginForm} />
        <Route exact path='/forgot-password/' component={ForgetForm} />
    </div>

);

export default BaseRouter;